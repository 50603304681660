import React, { useState } from 'react'
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    Heading,
    HStack,
    Image,
    Input,
    Stack,
    Text,
  } from '@chakra-ui/react'
  import logo from "../images/logo.jpg";
import { useDispatch } from 'react-redux';
import { sendOtpEmailAsync } from '../features/ContactUs/ContactUsSlice';
import { verifyOtpEmailAsync } from '../features/User/UserSlice';
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from 'react-router-dom';

const AdminOtp = () => {
  const dispatch  = useDispatch();
  const navigate = useNavigate();
  const [email,setEmail] = useState("");
  const [otp,setOtp] = useState("");
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeOtp = (event) => {
    setOtp(event.target.value);
  };
  const handleSentotp = () =>{
    dispatch(sendOtpEmailAsync(email)).then((res) => {
      if (res.payload.status === 1) {
        toast.success(res.payload.message, { style: { background: "green", color: "#ffffff" } });
      } else {
        toast.error(res.payload.message, { style: { background: "red", color: "#ffffff" } });
      }
    });
  }
  const handleLogin = () =>{
    dispatch(verifyOtpEmailAsync({email,otp})).then((res) => {
      if (res.payload.status === 1) {
        toast.success(res.payload.message, { style: { background: "green", color: "#ffffff" } });
          navigate('/dashboard');
      } else {
        toast.error(res.payload.message, { style: { background: "red", color: "#ffffff" } });
      }
    });
  }
  return (
        <Container maxW="lg"  marginTop={"50px"}  px={{ base: '0', sm: '8' }} py={{ base: '10' }} border={"1px solid #D1DCDA"} borderRadius={"50px"}>
         <Toaster position="bottom-center"  toastOptions={{ duration: 3000 }} />
          <Stack >
            <Stack >
            <Image src={logo} alt="logo" width={"60px"} height="80px" m={"auto"}/>
              <Stack  textAlign="center">
                <Heading size={{ base: 'xs', md: 'sm' }}>Login with OTP</Heading>
              </Stack>
            </Stack>
            <Box
              py={{ base: '0', sm: '8' }}
              px={{ base: '4', sm: '10' }}
              bg={{ base: 'transparent', sm: 'bg.surface' }}
              borderRadius={{ base: 'none', sm: 'xl' }}
            >
              <Stack spacing="6">
                  <FormControl>
                <Stack spacing="5">
                    <HStack>
                    <Input id="email" type="email" placeholder='Enter Email' value={email}  onChange={handleChange}/>
                    <Button onClick={ handleSentotp} bg={"#52771A"} color={"#fff"}>Send OTP</Button>
                    </HStack>
                  
                    <Input id="otp" type="number" placeholder='OTP'   value={otp}  onChange={handleChangeOtp}/>
                </Stack>
                  </FormControl>
                <Stack spacing="6">
                  <Button bg={"#52771A"} color={"#fff"}  onClick={ handleLogin}>Login</Button>
                  <HStack>
                    <Divider/>
                    <Link to='/'>
                    <Text textStyle="sm" whiteSpace="nowrap" color="blue">
                      Already have login credentials? Login with password
                    </Text>
                    </Link>
                    <Divider />
                  </HStack>
           
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Container>
      )
  
}

export default AdminOtp
