import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createCategory, deleteCategoryById, fetchCategory, fetchCategoryByAdmin, fetchCategoryById, toggleCategoryStatus, updateCategory } from "./CategoryAPI";

const initialState = {
  value: 0,
  status: "idle",
  categoryData: [],
  admincategoryData: [],
  categoryDataById: null,
  
 
};


export const createCategoryAsync = createAsyncThunk(
  "category/addCategory",
   async (CategoryData) => {
  const response = await createCategory(CategoryData);

  return response.data;
});
export const fetchCategoryAsync = createAsyncThunk(
  "category/fetchCategory",
  async () => {
    const response = await fetchCategory();

    return response.data;
  }
);
export const fetchCategoryAdminAsync = createAsyncThunk(
  "category/fetchCategoryByAdmin",
  async () => {
    const response = await fetchCategoryByAdmin();
       console.log(response.data,"pall");
    return response.data;
  }
);
export const updateCategoryAsync = createAsyncThunk(
  "category/updateCategory",
  async (id, data) => {
    console.log(id, data, "update");
    const response = await updateCategory(id, data);

    return response.data;
  }
);
export const fetchCategoryByIdAsync = createAsyncThunk(
  "category/fetchCategoryById",
  async (categoryId) => {
    const response = await fetchCategoryById(categoryId);

    return response.data;
  }
);
export const deleteCategoryAsync = createAsyncThunk(
  "category/deleteCategoryById",
  async (categoryId) => {
    const response = await deleteCategoryById(categoryId);
 console.log(response.data)
    return response.data;
  }
);
export const toggleCategoryStatusAsync = createAsyncThunk(
  "category/toggleCategoryStatus",
  async (categoryId) => {
    const response = await toggleCategoryStatus(categoryId);

    return response.data;
  }
);

export const CategorySlice = createSlice({
  name: "category",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.categoryData = action.payload;
      })
      .addCase(createCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.admincategoryData.push(action.payload.data);
      })
      .addCase(fetchCategoryAdminAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategoryAdminAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.admincategoryData = action.payload.data;
      })
      .addCase(fetchCategoryByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategoryByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.categoryDataById = action.payload;
      })
      .addCase(updateCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let newArr = state.admincategoryData.map(function (item) {
          if (item._id === action.payload.data.id) {
            return {...item,...action.payload.data.updated} ;
          } else {
            return item;
          }
        });
        state.admincategoryData = newArr;
      })
      .addCase(deleteCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";

        let newArr = state.admincategoryData.filter(function (item) {
          return item._id !== action.payload.data._id;
        });
        state.admincategoryData = newArr;
      })
      .addCase(toggleCategoryStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleCategoryStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
    let newArr = state.admincategoryData.map(function (item) {
  if (item._id === action.payload.data.id) {
    return { ...item, status: action.payload.data.status };
  } else {
    return item;
  }
      });
        state.admincategoryData = newArr;
      });

      
  },
});

// export const { increment } = UserSlice.actions;

export const selectCategory = (state) => state.category.categoryData; 
export const selectAdmincategoryData = (state) => state.category.admincategoryData; 
export const selectCategoryById = (state) => state.category.categoryDataById; 


export default CategorySlice.reducer;
