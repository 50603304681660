// A mock function to mimic making an async request for data
import axios from "axios";
export function createProduct(product) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/product/addProduct",
      product
    );
    const data = response.data;

    resolve({ data });
  });
}



export function fetchProductByAdmin() {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+"/product/getProductAdmin"
    );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchProductById(productId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/product/getProductById/${productId}`
    );

    const data = response.data;
    resolve({ data });
  });
}

export function updateProduct(productId, newProduct) {
  return new Promise(async (resolve) => {
   const response = await axios.put(process.env.REACT_APP_BASE_API+"/product/updateProduct", {
      productId: productId,
      productName: newProduct.productName,
      productImage: newProduct.productImage,
      productRate: newProduct.productRate,
      pricePerUnit: newProduct.pricePerUnit,
      productDescription: newProduct.productDescription,
      categoryName: newProduct.categoryName,
      subcategoryId: newProduct.subcategoryId,
      productSpicility: newProduct.productSpicility,
    });
    const data = response.data;
    resolve({ data });
  });
}
export function fetchProductBySubCategoryId(subcategoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/product/getProductBySubCategoryId/${subcategoryId}`
    );

    const data = response.data;
    resolve({ data });
  });
}
export function fetchProductByCategory(category) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+"/product/getProductBySubCategoryId",
      {
        categoryName: category,
      }
    );

    const data = response.data;
    resolve({ data });
  });
}

export function deleteProductById(productId) {
  return new Promise(async (resolve) => {
   const response = await axios.delete(
     `${process.env.REACT_APP_BASE_API}/product/deleteProduct/${productId}`
   );
    const data = response.data;
    resolve({ data });
  });
}
export function toggleProductStatus(productId) {
  console.log(productId, "productId");
  return new Promise(async (resolve) => {
   const response = await axios.patch(
     `${process.env.REACT_APP_BASE_API}/product/toggleProductStatus/${productId}`
   );
    const data = response.data;
    resolve({ data });
  });
}
