import React from "react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const SearchBar = () => {
  return (
    <InputGroup
      w="500px"
      h="50px"
      borderRadius="50px"
      border="1px solid #8E8E8E"
      bg="#FFF"
      mt={"33px"}
    >
      <Input
        w="500px"
        h="50px"
        borderRadius="50px"
        border="1px solid #8E8E8E"
        bg="#FFF"
        type="text"
        placeholder="Search Products"
        color="#404040"
        _placeholder={{ color: "#A0A0A0" }}
        fontFamily="Inter"
        fontSize="16px"
        fontStyle="normal"
        fontWeight={400}
        lineHeight="normal"
        letterSpacing="0.48px"
        pl="48px" // Adjust the padding based on your icon width
        pr="0"
        alignItems="center"
      />
      <InputRightElement pointerEvents="none" pl="3" pr="0" alignItems="center">
        <SearchIcon w="24px" h="24px" color="#404040" mt={"10px"} mr="40px" />
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchBar;
