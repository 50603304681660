import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { loginAdminSelector } from "../../features/User/UserSlice";


const PrivateRoutes = ({ children }) => {
const loginUser = useSelector(loginAdminSelector);
  if (loginUser?.status === 0) {
    return <Navigate to={"/"} />;
  }
  return children;
};
export default PrivateRoutes;
