import { Box, Text } from "@chakra-ui/react";
import "./Product.css";
import ProductCards from "./ProductCards";
import SearchBar from "./../SearchBar";
import { useSelector } from "react-redux";
import {
   selectadminSubcategoryData,
} from "../../features/Subcategory/SubcategorySlice";
import { useEffect } from "react";

const style = {
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  productCardsMainBox: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",

    margin: "auto",
    gap: "20px",

    marginLeft: "40px",
  },
};
const SubCategoryList = () => {
  const subcategory = useSelector(selectadminSubcategoryData);
  console.log(subcategory,'rendering')
 useEffect(() => {
  ;
 },[]);
  const heading = "Sub Category List";
  return (
    <Box>
      <Box display={"flex"} gap="200px" marginBottom={"30px"}>
        <Text style={style.formheading}>{heading}</Text>
        <SearchBar />
      </Box>
      <Box style={style.productCardsMainBox}>
        {subcategory.length > 0&&
          subcategory.map((el) => (
            <ProductCards
              active={el.status}
              id={el._id}
              heading={heading}
              name={el?.parentCategory?.categoryName}
              subname={el.subcategoryName}
              image={el?.subcategoryImage}
            />
          ))}
      </Box>
    </Box>
  );
};

export default SubCategoryList;
