// A mock function to mimic making an async request for data
import axios from "axios";
export function createSubcategory(subcategory) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/subcategory/addSubcategory",
      subcategory
    );
    const data = response.data;

    resolve({ data });
  });
}

export function fetchSubcategoryByAdmin() {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+"/subcategory/getSubcategoryAdmin"
    );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchSubCategoryById(subcategoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/subcategory/getSubcategoryById",
      {
        subcategoryId: subcategoryId,
      }
    );

    const data = response.data;
    resolve({ data });
  });
}

export function updateSubategory(subcategoryId, newSubcategory) {
  return new Promise(async (resolve) => {
   const response = await axios.put(
    process.env.REACT_APP_BASE_API+"/subcategory/updateSubcategory",
     {
       subcategoryId: subcategoryId,
       categoryId: newSubcategory.categoryId,
       subcategoryName: newSubcategory.subcategoryName,
       subcategoryImage: newSubcategory.subcategoryImage,
     }
   );
    const data = response.data;

    resolve({ data });
  });
}

export function deleteSubcategeoryById(subcategoryId) {
  return new Promise(async (resolve) => {
   const response = await axios.patch(
     `${process.env.REACT_APP_BASE_API}/subcategory/deleteSubcategory/${subcategoryId}`
   );
    const data = response.data;
    resolve({ data });
  });
}
export function toggleSubcategoryStatus(subcategoryId) {
  return new Promise(async (resolve) => {
   const response = await axios.patch(
     `${process.env.REACT_APP_BASE_API}/subcategory/toggleSubCategoryStatus/${subcategoryId}`
   );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchSubCategoryNameByCategoryId(categoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/subcategory/getSubcategoryName/${categoryId}`
    );

    const data = response.data;
    resolve({ data });
  });
}
export function fetchSubCategoryByCategoryId(categoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/subcategory/getSubcategoryByCategoryId/${categoryId}`
    );

    const data = response.data;
    resolve({ data });
  });
}
