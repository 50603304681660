import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createService,
  deleteServiceById,
  fetchService,
  fetchServiceByAdmin,
  fetchServiceById,
  toggleServiceStatus,
  updateService,
} from "./ServiceAPI";

const initialState = {
  value: 0,
  status: "idle",
  serviceData: [],
  adminservicedata : [],
};

export const createServiceAsync = createAsyncThunk(
  "service/addService",
  async (ServiceData) => {
    const response = await createService(ServiceData);

    return response.data;
  }
);

export const fetchServiceAdminAsync = createAsyncThunk(
  "service/fetchServiceByAdmin",
  async () => {
    const response = await fetchServiceByAdmin();
    console.log(response.data, "pall");
    return response.data;
  }
);
export const updateServiceAsync = createAsyncThunk(
  "service/updateService",
  async (id, data) => {
    console.log(id, data, "update");
    const response = await updateService(id, data);

    return response.data;
  }
);
export const fetchServiceByIdAsync = createAsyncThunk(
  "service/fetchServiceById",
  async (ServiceId) => {
    const response = await fetchServiceById(ServiceId);

    return response.data;
  }
);
export const deleteServiceAsync = createAsyncThunk(
  "service/deleteServiceById",
  async (ServiceId) => {
    const response = await deleteServiceById(ServiceId);
    console.log(response.data);
    return response.data;
  }
);
export const toggleServiceStatusAsync = createAsyncThunk(
  "Service/toggleServiceStatus",
  async (ServiceId) => {
    const response = await toggleServiceStatus(ServiceId);

    return response.data;
  }
);

export const ServiceSlice = createSlice({
  name: "service",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createServiceAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createServiceAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminservicedata.push(action.payload.data) ;
      })
     
      .addCase(fetchServiceAdminAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchServiceAdminAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminservicedata = action.payload.data;
      })
      .addCase(fetchServiceByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchServiceByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.serviceData = action.payload;
      })
      .addCase(updateServiceAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateServiceAsync.fulfilled, (state, action) => {
        state.status = "idle";
         let newArr = state.adminservicedata.map(function (item) {
           if (item._id === action.payload.data.id) {
             return { ...item, ...action.payload.data.updated };
           } else {
             return item;
           }
         });
        state.adminservicedata = newArr;
      })
      .addCase(deleteServiceAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteServiceAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let newArr = state.adminservicedata.filter(function (item) {
          return item._id !== action.payload.data._id;
        });
        state.adminservicedata = newArr;
      })
      .addCase(toggleServiceStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleServiceStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
         let newArr = state.adminservicedata.map(function (item) {
           if (item._id === action.payload.data.id) {
             return { ...item, status: action.payload.data.status };
           } else {
             return item;
           }
         });
        state.adminservicedata = newArr;
      });
  },
});

// export const { increment } = UserSlice.actions;

export const selectAdminServiceData = (state) => state.service.adminservicedata;

export default ServiceSlice.reducer;
