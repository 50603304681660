import React, { useCallback, useEffect , useState } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";

import "./Product.css";
import AdminEditModal from "../AdminModal/AdminEditModal";
import CategoryEdit from "../AdminModal/CategoryEdit";
import SubCategoryEdit from "../AdminModal/SubCategoryEdit";
import { useDispatch } from "react-redux";
import {
  deleteCategoryAsync,
  toggleCategoryStatusAsync,
} from "../../features/Category/CategorySlice";
import {
  deleteSubcategoryAsync,
  fetchSubcategoryByIdAsync,
  toggleSubategoryStatusAsync,
} from "../../features/Subcategory/SubcategorySlice";
import { toggleProductStatus } from "../../features/Product/ProductAPI";
import { deleteProductAsync, toggleProductStatusAsync } from "../../features/Product/ProductSlice";
const style = {
  activeButton: {
    borderLeft: "0.5px solid #FFF",
    borderRight: "0.5px solid #FFF",
  },
  buttonBox: {
    width: "100%",
    background: "#D9D9D9",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    borderRadius: "0px 0px 14px 14px",
  },
};

const ProductCards = ({ id, subname,subcategoryProduct, heading, name, price, pricePerUnit, image, active,productDescription,subcategoryId,productSpicility,categoryName,categoryDescription }) => {
  const dispatch = useDispatch();
  const [digit,setDigit] = useState(0);
  const handleCategeoryDelete = (categoryId) => {
    dispatch(deleteCategoryAsync(categoryId));
  };
  const handleSubcategeoryDelete = (subcategoryId) => {
    dispatch(deleteSubcategoryAsync(subcategoryId));
  };
  const handleProductDelete = (productId) => {
    dispatch(deleteProductAsync(productId));
  };

  const handleCategoryActive = (categoryId) => {
    dispatch(toggleCategoryStatusAsync(categoryId));
  };
  const handleSubcategoryActive = (subcategoryId) => {
    dispatch(toggleSubategoryStatusAsync(subcategoryId));
  };
  const handleProductActive = (productId) => {
    dispatch(toggleProductStatusAsync(productId));
  };
const handleSetDigit = () =>{
    heading === "Products"? setDigit(1):heading === "Category List"?
    setDigit(2): heading === "Sub Category List" ? setDigit(3) :  setDigit(0)
  
}
//  const fetchSubcategory = useCallback(async () => {
//    try {
//      await dispatch(fetchSubcategoryByIdAsync(subcategoryId));
//      console.log("Data fetched successfully.");
//    } catch (error) {
//      console.error("Error fetching data:", error);
//    }
//  }, [dispatch, subcategoryId]);

//  useEffect(() => {
//    fetchSubcategory();
//  }, [fetchSubcategory]);
  return (
    <Card
      id="product_box"
      style={
        !active
          ? {
              filter: !active ? "blur(2px)" : "none",
              opacity: !active ? 1 : 0.5,
            }
          : null
      }
    >
      {" "}
      {price && (
        <CardHeader>
          <Flex spacing="4" justify="flex-end">
            <Button id="price">{price}</Button>
          </Flex>
        </CardHeader>
      )}
      <Flex align="center" justify="center" height="100%">
        {Array.isArray(image) && (
          <Image
            objectFit="cover"
            width={"157px"}
            height={"157px"}
            src={image[0]}
            alt="Chakra UI"
          />
        )}
        {!Array.isArray(image) && (
          <Image
            objectFit="cover"
            width={"157px"}
            height={"157px"}
            src={image}
            alt="Chakra UI"
          />
        )}
      </Flex>
      <Text id="product_title">{name}</Text>
      {subname && <Text id="product_title">{subname}</Text>}
      <Box style={style.buttonBox}>
        <Button
          variant="ghost"
          bg="#52771A"
          onClick={() => {
            handleSetDigit();
          }}
          borderRadius={"0px 0px 0px 14px"}
        >
          {digit === 1 ? (
            <AdminEditModal
              id={id}
              name={name}
              price={price}
              image={image}
              productSpicility={productSpicility}
              pricePerUnit={pricePerUnit}
              subcategoryId={subcategoryId}
              productDescription={productDescription}
              categoryName={categoryName}
              subcategoryProduct={subcategoryProduct}
            />
          ) : digit === 2 ? (
            <CategoryEdit
              name={name}
              image={image}
              id={id}
              categoryDescription={categoryDescription}
            />
          ) : digit === 3 ? (
            <SubCategoryEdit
              name={name}
              subname={subname}
              image={image}
              id={id}
            />
          ) : (
            <Button variant="ghost">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_182_253)">
                  <path
                    d="M16.2114 8.67152C15.8878 8.67152 15.6255 8.93387 15.6255 9.25747V17.8515C15.6255 18.39 15.1874 18.8281 14.6489 18.8281H2.1485C1.61001 18.8281 1.17191 18.39 1.17191 17.8515V5.3511C1.17191 4.81261 1.61001 4.37451 2.1485 4.37451H10.7425C11.0661 4.37451 11.3285 4.11216 11.3285 3.78855C11.3285 3.46495 11.0661 3.2026 10.7425 3.2026H2.1485C0.963819 3.2026 0 4.16642 0 5.3511V17.8515C0 19.0362 0.963819 20 2.1485 20H14.6489C15.8336 20 16.7974 19.0362 16.7974 17.8515V9.25747C16.7974 8.93387 16.535 8.67152 16.2114 8.67152Z"
                    fill="black"
                  />
                  <path
                    d="M19.5997 1.50473L18.4948 0.399817C17.9618 -0.133247 17.0944 -0.133247 16.5612 0.399817L7.72218 9.23892C7.64038 9.32072 7.58464 9.4249 7.5619 9.53834L7.00943 12.3006C6.97103 12.4927 7.03114 12.6913 7.1697 12.8298C7.28068 12.9408 7.43018 13.0015 7.58401 13.0015C7.62226 13.0015 7.6607 12.9977 7.6989 12.9901L10.4611 12.4376C10.5746 12.4149 10.6788 12.3591 10.7606 12.2773L19.5997 3.43827C19.5997 3.43827 19.5997 3.43827 19.5997 3.43823C20.1328 2.90521 20.1328 2.03784 19.5997 1.50473ZM10.0574 11.3232L8.33099 11.6686L8.67631 9.94219L15.8707 2.7477L17.2518 4.12884L10.0574 11.3232ZM18.771 2.60961L18.0805 3.30018L16.6993 1.91904L17.3899 1.22851C17.466 1.15234 17.59 1.1523 17.6661 1.22848L18.771 2.33339C18.8472 2.40953 18.8472 2.53348 18.771 2.60961Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_182_253">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Button>
          )}
        </Button>
        <Button
          bg="#F6AA00"
          onClick={() => {
            if (heading === "Category List") {
              handleCategoryActive(id);
            } else if (heading === "Sub Category List") {
              handleSubcategoryActive(id);
            } else if (heading === "Products") {
              handleProductActive(id);
            }
          }}
          style={style.activeButton}
          variant="ghost"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_182_230)">
              <path
                d="M3.81092 19.1667H6.0803C6.43676 19.1667 6.72592 18.8981 6.72592 18.5417C6.72592 18.1852 6.43697 17.9167 6.0803 17.9167H3.81092C2.85551 17.9167 2.08301 17.0687 2.08301 16.0769V6.875H17.708V9.22542C17.708 9.58187 18.0807 9.87104 18.4372 9.87104C18.7936 9.87104 19.1663 9.58208 19.1663 9.22542V3.93625C19.1663 2.25437 17.5857 0.833332 15.9218 0.833332H3.81092C2.14363 0.833332 0.833008 2.22521 0.833008 3.93625V16.0769C0.833008 17.7806 2.14363 19.1667 3.81092 19.1667ZM2.08301 3.93625C2.08301 2.95416 2.87176 2.08333 3.81092 2.08333H15.9218C16.8857 2.08333 17.708 2.95416 17.708 3.93625V5.625H2.08301V3.93625Z"
                fill="black"
              />
              <path
                d="M15.5053 3.33333H15.2472C14.8907 3.33333 14.6016 3.70604 14.6016 4.0625C14.6016 4.41896 14.8905 4.79167 15.2472 4.79167H15.5053C15.8618 4.79167 16.1509 4.41896 16.1509 4.0625C16.1509 3.70604 15.8618 3.33333 15.5053 3.33333Z"
                fill="black"
              />
              <path
                d="M13.311 3.33333H13.0529C12.6964 3.33333 12.4072 3.70604 12.4072 4.0625C12.4072 4.41896 12.6962 4.79167 13.0529 4.79167H13.311C13.6674 4.79167 13.9566 4.41896 13.9566 4.0625C13.9566 3.70604 13.6674 3.33333 13.311 3.33333Z"
                fill="black"
              />
              <path
                d="M10.9868 3.33333H10.7286C10.3722 3.33333 10.083 3.70604 10.083 4.0625C10.083 4.41896 10.372 4.79167 10.7286 4.79167H10.9868C11.3432 4.79167 11.6324 4.41896 11.6324 4.0625C11.6324 3.70604 11.3432 3.33333 10.9868 3.33333Z"
                fill="black"
              />
              <path
                d="M12.7077 9.81979C9.87688 9.81979 7.29167 11.5975 6.25 14.2435C6.25 14.2435 7.60521 19.234 12.7077 19.1252C18.099 19.0104 19.1667 14.2435 19.1667 14.2435C18.125 11.5975 15.5394 9.81979 12.7077 9.81979ZM12.7538 17.8342C10.5383 17.8342 8.515 16.4931 7.64208 14.4725C8.51479 12.4519 10.5383 11.1108 12.7538 11.1108C14.9698 11.1108 16.9935 12.4519 17.8665 14.4725C16.9938 16.4931 14.9698 17.8342 12.7538 17.8342Z"
                fill="black"
              />
              <path
                d="M12.754 11.9677C11.3728 11.9677 10.249 13.0913 10.249 14.4725C10.249 15.8538 11.3728 16.9773 12.754 16.9773C14.1353 16.9773 15.2588 15.8538 15.2588 14.4725C15.2588 13.0913 14.1353 11.9677 12.754 11.9677ZM12.754 15.6863C12.0846 15.6863 11.5401 15.1417 11.5401 14.4725C11.5401 13.8033 12.0846 13.2588 12.754 13.2588C13.4234 13.2588 13.9678 13.8033 13.9678 14.4725C13.9678 15.1417 13.4234 15.6863 12.754 15.6863Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_182_230">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Button>
        <Button
          bg="#F03800"
          borderRadius={"0px 0px 14px 0px"}
          onClick={() => {
            if (heading === "Category List") {
              handleCategeoryDelete(id);
            } else if (heading === "Sub Category List") {
              handleSubcategeoryDelete(id);
            } else if (heading === "Products") {
              handleProductDelete(id);
            }
          }}
          variant="ghost"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M16.1797 2.22656H13.3965V1.66992C13.3965 0.749127 12.6474 0 11.7266 0H7.27344C6.35264 0 5.60352 0.749127 5.60352 1.66992V2.22656H2.82031C1.89952 2.22656 1.15039 2.97569 1.15039 3.89648C1.15039 4.636 1.63374 5.26437 2.30097 5.48332L3.29383 17.4687C3.36534 18.3274 4.09632 19 4.95796 19H14.042C14.9037 19 15.6347 18.3274 15.7062 17.4685L16.699 5.48328C17.3663 5.26437 17.8496 4.636 17.8496 3.89648C17.8496 2.97569 17.1005 2.22656 16.1797 2.22656ZM6.7168 1.66992C6.7168 1.36299 6.96651 1.11328 7.27344 1.11328H11.7266C12.0335 1.11328 12.2832 1.36299 12.2832 1.66992V2.22656H6.7168V1.66992ZM14.5968 17.3763C14.5729 17.6625 14.3293 17.8867 14.042 17.8867H4.95796C4.67077 17.8867 4.42711 17.6625 4.40332 17.3766L3.42497 5.56641H15.575L14.5968 17.3763ZM16.1797 4.45312H2.82031C2.51338 4.45312 2.26367 4.20342 2.26367 3.89648C2.26367 3.58955 2.51338 3.33984 2.82031 3.33984H16.1797C16.4866 3.33984 16.7363 3.58955 16.7363 3.89648C16.7363 4.20342 16.4866 4.45312 16.1797 4.45312Z"
              fill="black"
            />
            <path
              d="M7.2724 16.1823L6.71576 7.20186C6.69673 6.895 6.43114 6.66162 6.12576 6.68073C5.81891 6.69977 5.5856 6.96391 5.6046 7.27073L6.16124 16.2512C6.17953 16.5464 6.4246 16.7734 6.71628 16.7734C7.03865 16.7734 7.29218 16.5019 7.2724 16.1823Z"
              fill="black"
            />
            <path
              d="M9.5 6.67969C9.19259 6.67969 8.94336 6.92891 8.94336 7.23633V16.2168C8.94336 16.5242 9.19259 16.7734 9.5 16.7734C9.80741 16.7734 10.0566 16.5242 10.0566 16.2168V7.23633C10.0566 6.92891 9.80741 6.67969 9.5 6.67969Z"
              fill="black"
            />
            <path
              d="M12.8743 6.68076C12.5681 6.66173 12.3033 6.89503 12.2843 7.20189L11.7276 16.1824C11.7087 16.4892 11.942 16.7533 12.2488 16.7724C12.5558 16.7914 12.8198 16.5579 12.8388 16.2512L13.3954 7.27076C13.4144 6.96391 13.1811 6.69976 12.8743 6.68076Z"
              fill="black"
            />
          </svg>
        </Button>
      </Box>
    </Card>
  );
};

export default ProductCards;
