import "./App.css";
import RouterPage from "./RouterPages/RouterPage";
import AdminNavbar from "./Admin/AdminNavbar/AdminNavbar";
import { Box, Hide } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  authMiddlewareAsync,
  loginAdminSelector,
} from "./features/User/UserSlice";
import Sidebar from "./Admin/Sidebar/Sidebar";
import LoginRouterPage from "./RouterPages/LoginRoutePage";

function App() {
  const loginAdmin = useSelector(loginAdminSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authMiddlewareAsync());
   
  }, []);
   
  return (
    <>
   { loginAdmin?.status !== 1 && <LoginRouterPage />}
    {loginAdmin?.status == 1 && (
      <>
        <AdminNavbar/>
        <Box display={"flex"}>
          <Box>
            <Sidebar />
          </Box>
          <Box display={"flex"} flexDirection="column" width="100%">
            <RouterPage/>
          </Box>
        </Box>{" "}
      </>
    )}
  </>
  );
}

export default App;
