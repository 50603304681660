import { Box, Button, Input, Text, Textarea } from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { createServiceAsync } from "../../features/Service/ServiceSlice";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";


const style = {
  form_mainbox: {
    width: "600px",
    borderRadius: "28px",
    background: "#f3ffe1",
    padding: "24px",
    margin: "auto",
    marginTop: "20px",
  },
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  formInput: {
    width: "100%",
    height: "50px",
    borderRadius: "14px",
    border: "1px solid #A0A0A0",
    backgroundColor: "#FFF",
    paddingLeft: "10px",
    marginBottom: "12px",
  },
  formText: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "6px",
  },
  formButton: {
    borderRadius: "14px",
    background: "#52771A",
    color: "#f3ffe1",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px 16px",
  },
  btnbox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  errorstyle: {
    color: "red",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "4px",
  },
};
const AddService = () => {

    const dispatch = useDispatch();
    const {
      register,
      handleSubmit,
        setValue,
      formState: { errors },
    } = useForm();

    const onSubmit = (data) => dispatch(createServiceAsync(data).then((res) =>{
      if (res?.payload?.status === 1) {
      toast.success("Service Added SucessFully");
        setValue("serviceName", "");
        setValue("content", "");
        setValue("vedioLink", "");
      }
       
    })); ;
  return (
    <Box>
      <Toaster toastOptions={{ duration: 3000 }} />

      <Box display={"flex"} justifyContent="space-between">
        <Text style={style.formheading}>Add New Service</Text>
        <Link to="/servicelist">
          <Button style={style.formButton} mr="50px" mt={"45px"}>
            SHOW SERVICE LIST
          </Button>
        </Link>
      </Box>

      <Box style={style.form_mainbox}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text style={style.formText}>Service Name</Text>
          <Input
            id="serviceName"
            {...register("serviceName", {
              required: "service name must be required",
            })}
            style={style.formInput}
          />
          {errors.serviceName && (
            <span style={style.errorstyle}>{errors.serviceName.message}</span>
          )}
          <Text style={style.formText}>Service Description</Text>
          <Textarea
            id="content"
            {...register("content", {
              required: "service description must be required",
            })}
            style={style.formInput}
          />
          {errors.content && (
            <span style={style.errorstyle}>{errors.content.message}</span>
          )}
          <Text style={style.formText}>Sevice Youtube Video Link</Text>
          <Input
            id="vedioLink"
            {...register("vedioLink", {
              required: "vedio link must be required",
            })}
            style={style.formInput}
          />
          {errors.vedioLink && (
            <span style={style.errorstyle}>{errors.vedioLink.message}</span>
          )}

          <Box style={style.btnbox}>
            <Button type="submit" style={style.formButton}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default AddService;
