import React from "react";
import { Box, Button, Card, Image, Text } from "@chakra-ui/react";
import { FaYoutube } from "react-icons/fa"; // Import the YouTube icon
import "./Product.css";

import { useDispatch } from "react-redux";

import ServiceEditModel from "../AdminModal/ServiceEditModel";
import {
  deleteServiceAsync,
  toggleServiceStatusAsync,
} from "../../features/Service/ServiceSlice";
import Pills from "../../component/constant/Pills";
import {
  deleteGovermentschmeAsync,
  toggleGovermentschmeStatusAsync,
} from "../../features/Govermentschme/GovermentschmeSlice";
import GovermentschmeEdit from "../AdminModal/GovermentschmeEdit";
const style = {
  activeButton: {
    borderLeft: "0.5px solid #FFF",
    borderRight: "0.5px solid #FFF",
  },
  buttonBox: {
    background: "#D9D9D9",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    borderRadius: "0px 0px 14px 14px",
  },
};

const ServiceCard = ({
  id,
  name,
  vedioLink,
  active,
  content,
  state,
  image,
  page,
  govermentschmeState,
}) => {
  const dispatch = useDispatch();
   console.log(name,"BOL");
  const handleServiceDelete = (serviceId) => {
    dispatch(deleteServiceAsync(serviceId));
  };
  const handleServiceActive = (serviceId) => {
    dispatch(toggleServiceStatusAsync(serviceId));
  };
  const handleGovermentActive = (
    govermentschmeId,
    currentPage,
    govermentschmeState
  ) => {
    dispatch(
      toggleGovermentschmeStatusAsync({
        govermentschmeId,
        currentPage,
        govermentschmeState,
      })
    );
  };
  const handleGovermentdelete = (
    govermentschmeId,
    currentPage,
    govermentschmeState
  ) => {
    dispatch(
      deleteGovermentschmeAsync({
        govermentschmeId,
        currentPage,
        govermentschmeState,
      })
    );
  };

  return (
    <Card
      marginBottom={"40px"}
      width={"100%"}
      height="auto"
      borderRadius="14px"
      backgroundColor="#F5F5F5"
      padding={"20px"}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Text fontSize={30}>{name}</Text>
        {state ? <Pills name={state} /> : null}
      </Box>

      <Text>{content}</Text>
      <Box display={"flex"}>
        {image ? (
          <Image src={image} width={50} height={30} />
        ) : (
          <FaYoutube size={24} color="#FF0000" />
        )}

        <a
          href={image ? image : vedioLink}
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <Text>{image ? image : vedioLink}</Text>
        </a>
      </Box>

      <Box style={style.buttonBox}>
        <Button variant="ghost">
          {state ? (
            <GovermentschmeEdit
              name={name}
              image={image}
              state={state}
              content={content}
              govermentschmeId={id}
              currentPage={page}
              govermentschmeState={govermentschmeState}
            />
          ) : (
            <ServiceEditModel
              name={name}
              vedioLink={vedioLink}
              content={content}
              id={id}
            />
          )}
        </Button>
        <Button
          bg={!active ? "gray" : "transparent"}
          onClick={() => {
            {
              state
                ? handleGovermentActive(id, page, govermentschmeState)
                : handleServiceActive(id);
            }
          }}
          style={style.activeButton}
          variant="ghost"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_182_230)">
              <path
                d="M3.81092 19.1667H6.0803C6.43676 19.1667 6.72592 18.8981 6.72592 18.5417C6.72592 18.1852 6.43697 17.9167 6.0803 17.9167H3.81092C2.85551 17.9167 2.08301 17.0687 2.08301 16.0769V6.875H17.708V9.22542C17.708 9.58187 18.0807 9.87104 18.4372 9.87104C18.7936 9.87104 19.1663 9.58208 19.1663 9.22542V3.93625C19.1663 2.25437 17.5857 0.833332 15.9218 0.833332H3.81092C2.14363 0.833332 0.833008 2.22521 0.833008 3.93625V16.0769C0.833008 17.7806 2.14363 19.1667 3.81092 19.1667ZM2.08301 3.93625C2.08301 2.95416 2.87176 2.08333 3.81092 2.08333H15.9218C16.8857 2.08333 17.708 2.95416 17.708 3.93625V5.625H2.08301V3.93625Z"
                fill="black"
              />
              <path
                d="M15.5053 3.33333H15.2472C14.8907 3.33333 14.6016 3.70604 14.6016 4.0625C14.6016 4.41896 14.8905 4.79167 15.2472 4.79167H15.5053C15.8618 4.79167 16.1509 4.41896 16.1509 4.0625C16.1509 3.70604 15.8618 3.33333 15.5053 3.33333Z"
                fill="black"
              />
              <path
                d="M13.311 3.33333H13.0529C12.6964 3.33333 12.4072 3.70604 12.4072 4.0625C12.4072 4.41896 12.6962 4.79167 13.0529 4.79167H13.311C13.6674 4.79167 13.9566 4.41896 13.9566 4.0625C13.9566 3.70604 13.6674 3.33333 13.311 3.33333Z"
                fill="black"
              />
              <path
                d="M10.9868 3.33333H10.7286C10.3722 3.33333 10.083 3.70604 10.083 4.0625C10.083 4.41896 10.372 4.79167 10.7286 4.79167H10.9868C11.3432 4.79167 11.6324 4.41896 11.6324 4.0625C11.6324 3.70604 11.3432 3.33333 10.9868 3.33333Z"
                fill="black"
              />
              <path
                d="M12.7077 9.81979C9.87688 9.81979 7.29167 11.5975 6.25 14.2435C6.25 14.2435 7.60521 19.234 12.7077 19.1252C18.099 19.0104 19.1667 14.2435 19.1667 14.2435C18.125 11.5975 15.5394 9.81979 12.7077 9.81979ZM12.7538 17.8342C10.5383 17.8342 8.515 16.4931 7.64208 14.4725C8.51479 12.4519 10.5383 11.1108 12.7538 11.1108C14.9698 11.1108 16.9935 12.4519 17.8665 14.4725C16.9938 16.4931 14.9698 17.8342 12.7538 17.8342Z"
                fill="black"
              />
              <path
                d="M12.754 11.9677C11.3728 11.9677 10.249 13.0913 10.249 14.4725C10.249 15.8538 11.3728 16.9773 12.754 16.9773C14.1353 16.9773 15.2588 15.8538 15.2588 14.4725C15.2588 13.0913 14.1353 11.9677 12.754 11.9677ZM12.754 15.6863C12.0846 15.6863 11.5401 15.1417 11.5401 14.4725C11.5401 13.8033 12.0846 13.2588 12.754 13.2588C13.4234 13.2588 13.9678 13.8033 13.9678 14.4725C13.9678 15.1417 13.4234 15.6863 12.754 15.6863Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_182_230">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Button>
        <Button
          onClick={() => {
            {
              state
                ? handleGovermentdelete(id, page, govermentschmeState)
                : handleServiceDelete(id);
            }
          }}
          variant="ghost"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M16.1797 2.22656H13.3965V1.66992C13.3965 0.749127 12.6474 0 11.7266 0H7.27344C6.35264 0 5.60352 0.749127 5.60352 1.66992V2.22656H2.82031C1.89952 2.22656 1.15039 2.97569 1.15039 3.89648C1.15039 4.636 1.63374 5.26437 2.30097 5.48332L3.29383 17.4687C3.36534 18.3274 4.09632 19 4.95796 19H14.042C14.9037 19 15.6347 18.3274 15.7062 17.4685L16.699 5.48328C17.3663 5.26437 17.8496 4.636 17.8496 3.89648C17.8496 2.97569 17.1005 2.22656 16.1797 2.22656ZM6.7168 1.66992C6.7168 1.36299 6.96651 1.11328 7.27344 1.11328H11.7266C12.0335 1.11328 12.2832 1.36299 12.2832 1.66992V2.22656H6.7168V1.66992ZM14.5968 17.3763C14.5729 17.6625 14.3293 17.8867 14.042 17.8867H4.95796C4.67077 17.8867 4.42711 17.6625 4.40332 17.3766L3.42497 5.56641H15.575L14.5968 17.3763ZM16.1797 4.45312H2.82031C2.51338 4.45312 2.26367 4.20342 2.26367 3.89648C2.26367 3.58955 2.51338 3.33984 2.82031 3.33984H16.1797C16.4866 3.33984 16.7363 3.58955 16.7363 3.89648C16.7363 4.20342 16.4866 4.45312 16.1797 4.45312Z"
              fill="black"
            />
            <path
              d="M7.2724 16.1823L6.71576 7.20186C6.69673 6.895 6.43114 6.66162 6.12576 6.68073C5.81891 6.69977 5.5856 6.96391 5.6046 7.27073L6.16124 16.2512C6.17953 16.5464 6.4246 16.7734 6.71628 16.7734C7.03865 16.7734 7.29218 16.5019 7.2724 16.1823Z"
              fill="black"
            />
            <path
              d="M9.5 6.67969C9.19259 6.67969 8.94336 6.92891 8.94336 7.23633V16.2168C8.94336 16.5242 9.19259 16.7734 9.5 16.7734C9.80741 16.7734 10.0566 16.5242 10.0566 16.2168V7.23633C10.0566 6.92891 9.80741 6.67969 9.5 6.67969Z"
              fill="black"
            />
            <path
              d="M12.8743 6.68076C12.5681 6.66173 12.3033 6.89503 12.2843 7.20189L11.7276 16.1824C11.7087 16.4892 11.942 16.7533 12.2488 16.7724C12.5558 16.7914 12.8198 16.5579 12.8388 16.2512L13.3954 7.27076C13.4144 6.96391 13.1811 6.69976 12.8743 6.68076Z"
              fill="black"
            />
          </svg>
        </Button>
      </Box>
    </Card>
  );
};

export default ServiceCard;
