import { Box, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import "./Product.css";

import { useSelector } from "react-redux";

import ServiceCard from "./ServiceCard";
import {selectAdminServiceData } from "../../features/Service/ServiceSlice";

const style = {
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  productCardsMainBox: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",

    margin: "auto",
    gap: "40px",
  },
};
const ServiceList = () => {

  const service = useSelector(selectAdminServiceData);
  
  return (
    <Box>
      <Box display={"flex"} gap="200px" marginBottom={"30px"}>
        <Text style={style.formheading}>Service List</Text>
      </Box>
      <Box>
        {service.length > 0 &&
          service.map((el) => (
            <ServiceCard
              active={el.status}
              id={el._id}
              name={el?.serviceName}
              content={el.content}
              vedioLink={el?.vedioLink}
            />
          ))}
      </Box>
    </Box>
  );
};

export default ServiceList;
