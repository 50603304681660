import axios from "axios";


export function createCategory(category) {
  return new Promise(async (resolve) => {
  const response = await axios.post(
   process.env.REACT_APP_BASE_API  +"/category/addCategory",
    category
  );
      const data = response.data;
    resolve({ data });
  });
}

export function fetchCategory() {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+ "/category/getCategory"
    );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchCategoryByAdmin() {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+"/category/getCategoryAdmin"
    );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchCategoryById(categoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/category/getCategoryById",
      {
        categoryId: categoryId,
      }
    );

    const data = response.data;
    resolve({ data });
  });
}

export function updateCategory(categoryId, newCategory) {
  return new Promise(async (resolve) => {
    const response = await axios.put( process.env.REACT_APP_BASE_API+"/category/updateCategory", {
      categoryId: categoryId,
      categoryName: newCategory.categoryName,
      categoryImage: newCategory.categoryImage,
      categoryDescription: newCategory.categoryDescription,
    });
    const data = response.data;
    resolve({ data });
  });
}

export function deleteCategoryById(categoryId) {
  return new Promise(async (resolve) => {
   const response = await axios.patch(
     `${ process.env.REACT_APP_BASE_API}/category/deleteCategory/${categoryId}`
   );
    const data = response.data;
    resolve({ data });
  });
}
export function toggleCategoryStatus(categoryId) {
  return new Promise(async (resolve) => {
   const response = await axios.patch(
     `${ process.env.REACT_APP_BASE_API}/category/toggleCategoryStatus/${categoryId}`
   );
     const data = response.data;
    resolve({ data });
  });
}
