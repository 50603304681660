import { Box, Text } from "@chakra-ui/react";
import React from "react";
import "./Product.css";
import ProductCards from "./ProductCards";
import SearchBar from "./../SearchBar";
import { useSelector } from "react-redux";
import { selectProductAdmin } from "../../features/Product/ProductSlice";

const style = {
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  productCardsMainBox: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",

    margin: "auto",
    gap: "20px",

    marginLeft: "40px",
  },
};
const Products = () => {
 
  const product = useSelector(selectProductAdmin);
  const heading = "Products";
  return (
    <Box>
      <Box display={"flex"} gap="200px" marginBottom={"30px"}>
        <Text style={style.formheading}>{heading}</Text>
        <SearchBar />
      </Box>
      <Box style={style.productCardsMainBox}>
        {product.length > 0 &&
          product.map((el) => (
            <ProductCards
              active={el.status}
              price={el.productRate}
              id={el._id}
              heading={heading}
              productDescription={el.productDescription}
              name={el?.productName}
              categoryName={el.categoryName}
              image={el?.productImage}
              pricePerUnit={el.pricePerUnit}
              subcategoryId={el.subcategoryId}
              subcategoryProduct={el.subcategoryId?.subcategoryName}
              productSpicility={el.productSpicility}
            />
          ))}
      </Box>
    </Box>
  );
};

export default Products;
