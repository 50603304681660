import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createSubcategory,
  deleteSubcategeoryById,
  fetchSubCategoryByCategoryId,
  fetchSubCategoryById,
  fetchSubCategoryNameByCategoryId,
  fetchSubcategory,
  fetchSubcategoryByAdmin,
  toggleSubcategoryStatus,
  updateSubategory,
} from "./SubategoryAPI";
const initialState = {
  value: 0,
  status: "idle",
  subcategoryData: [],
  adminsubcategoryData: [],
  subcategoryByCategoryName: null,
  subcategoryByCategoryId: null,
  subcategoryById: [],
};

export const createSubcategoryAsync = createAsyncThunk(
  "subcategory/createSubcategory",
  async (SubsubcategoryData) => {
    const response = await createSubcategory(SubsubcategoryData);

    return response.data;
  }
);
export const fetchSubcategoryAdminAsync = createAsyncThunk(
  "subcategory/fetchSubcategoryByAdmin",
  async () => {
    const response = await fetchSubcategoryByAdmin();
    return response.data;
  }
);
export const updateSubcategoryAsync = createAsyncThunk(
  "subcategory/updateSubategory",
  async ({ id, data }) => {
    console.log(id, data, "update");
    const response = await updateSubategory(id, data);

    return response.data;
  }
);
export const fetchSubcategoryByIdAsync = createAsyncThunk(
  "subcategory/fetchSubCategoryById",
  async (subcategoryId) => {
    const response = await fetchSubCategoryById(subcategoryId);

    return response.data;
  }
);
export const deleteSubcategoryAsync = createAsyncThunk(
  "subcategory/deleteSubcategeoryById",
  async (subcategoryId) => {
    const response = await deleteSubcategeoryById(subcategoryId);
    console.log(response.data);
    return response.data;
  }
);
export const toggleSubategoryStatusAsync = createAsyncThunk(
  "subcategory/toggleSubcategoryStatus",
  async (subcategoryId) => {
    const response = await toggleSubcategoryStatus(subcategoryId);
    console.log(response.data, "meeting");
    return response.data;
  }
);

export const fetchSubCategoryNameByCategoryIdAsync = createAsyncThunk(
  "subcategory/fetchSubCategoryNameByCategoryId",
  async (categoryId) => {
    const response = await fetchSubCategoryNameByCategoryId(categoryId);

    return response.data;
  }
);

export const fetchSubCategoryByCategoryIdAsync = createAsyncThunk(
  "subcategory/fetchSubCategoryByCategoryId",
  async (categoryId) => {
    const response = await fetchSubCategoryByCategoryId(categoryId);

    return response.data;
  }
);

export const CategorySlice = createSlice({
  name: "category",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      
      .addCase(createSubcategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSubcategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminsubcategoryData.push(action.payload.data);
      })
      .addCase(fetchSubcategoryAdminAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubcategoryAdminAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminsubcategoryData = action.payload.data;
      })
      .addCase(fetchSubcategoryByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubcategoryByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.subcategoryById.push(action.payload.data[0]);
      })
      .addCase(updateSubcategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSubcategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
            let newArr = state.adminsubcategoryData.map(function (item) {
              if (item._id === action.payload.data.id) {
                return { ...item, ...action.payload.data.updated };
              } else {
                return item;
              }
            });
        state.adminsubcategoryData = newArr;
      })
      .addCase(deleteSubcategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSubcategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
          let newArr = state.adminsubcategoryData.filter(function (item) {
            return item._id !== action.payload.data._id;
          });
        state.adminsubcategoryData = newArr;
      })
      .addCase(toggleSubategoryStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleSubategoryStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let newArr = state.adminsubcategoryData.map(function (item) {
          if (item._id === action.payload.data.id) {
            return { ...item, status: action.payload.data.status };
          } else {
            return item;
          }
        });
        state.adminsubcategoryData = newArr;
      })
      .addCase(fetchSubCategoryNameByCategoryIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchSubCategoryNameByCategoryIdAsync.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.subcategoryByCategoryName = action.payload;
        }
      )
      .addCase(fetchSubCategoryByCategoryIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubCategoryByCategoryIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.subcategoryByCategoryId = action.payload;
      });
  },
});

// export const { increment } = UserSlice.actions;

export const selectadminSubcategoryData = (state) =>
  state.subcategory.adminsubcategoryData;
export const selectSubcategoryByCategoryName = (state) =>
  state.subcategory.subcategoryByCategoryName;
export const selectSubcategoryByCategoryId = (state) =>
  state.subcategory.subcategoryByCategoryId;
export const selectSubcategoryById = (state) =>
  state.subcategory.subcategoryById;

export default CategorySlice.reducer;
