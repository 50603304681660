import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { addDeliveryAddress, loginAdmin,updateUser,authMiddleware,verifyOtpEmail,deleteDeliveryAddress,getDeliveryAddress, fetchSelectedAddress, updateDeliveryAddress, updateSelectedAddress } from "./UserAPI";

const initialState = {
  value: 0,
  status: "idle",
  userdata: null,
  checkNo: null,
  loginUserData: null,
  userAddress:{data:[]},
  selectedAddressData: null,
  adminlogin: null
};
export const fetchSelectedAddressAsync = createAsyncThunk(
  "user/fetchSelectedAddress",
  async ({ id }) => {
    const response = await fetchSelectedAddress(id);

    return response.data;
  }
);
export const updateDeliveryAddressAsync = createAsyncThunk(
  "user/updateDeliveryAddress",
  async ({ user_id, address_id, data }) => {
    const response = await updateDeliveryAddress(user_id, address_id, data);
    return response.data;
  }
);
export const updateSelectedAddressAsync = createAsyncThunk(
  "user/updateSelectedAddress",
  async ({ user_id, address_id}) => {
    const response = await updateSelectedAddress(user_id, address_id);
    return response.data;
  }
);
export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async ({UserData,userId}) => {
    const response = await updateUser({UserData,userId});
    return response.data;
  }
);
export const deleteDeliveryAddressAsync = createAsyncThunk(
  "user/deleteDeliveryAddress",
  async ({ user_id, address_id }) => {
    const response = await deleteDeliveryAddress(user_id, address_id);
    return response.data;
  }
);
export const addDeliveryAddressrAsync = createAsyncThunk(
  "user/addDeliveryAddress",
  async ({ id, data }) => {
    const response = await addDeliveryAddress(id, data);
    return response.data;
  }
);
export const getDeliveryAddressrAsync = createAsyncThunk(
  "user/getDeliveryAddress",
  async ({ id, data }) => {
    const response = await getDeliveryAddress(id);
    return response.data;
  }
);


export const loginAdminAsync = createAsyncThunk(
  "user/loginAdmin",
  async (data) => {
    const response = await loginAdmin(data);
    return response.data;
  }
);
export const authMiddlewareAsync = createAsyncThunk(
  "user/authMiddleware",
  async () => {
    const response = await authMiddleware();
    return response.data;
  }
);
export const verifyOtpEmailAsync = createAsyncThunk(
  "user/verifyOtpEmail",
  async ({email,otp}) => {
    const response = await verifyOtpEmail({email,otp});
    return response.data;
  }
);

export const UserSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAdminAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAdminAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminlogin = action.payload;
      })
      .addCase(verifyOtpEmailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOtpEmailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminlogin = action.payload;
      })
      .addCase(authMiddlewareAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(authMiddlewareAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminlogin = action.payload;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.userdata = action.payload;
      })
      .addCase(fetchSelectedAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSelectedAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.selectedAddressData = action.payload;
      })
      .addCase(getDeliveryAddressrAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDeliveryAddressrAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.userAddress = action.payload;
      })
      .addCase(addDeliveryAddressrAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addDeliveryAddressrAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.userAddress.data.push(action.payload.data);
      })
      .addCase(updateDeliveryAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDeliveryAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";

        let newArr = state.userAddress.data?.map(function (
          item
        ) {
          if (item._id === action.payload.data._id) {
            return { ...item, ...action.payload.data};
          } else {
            return item;
          }
        });
        state.userAddress ={...state.userAddress , data:newArr} ;
      })
      .addCase(updateSelectedAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSelectedAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.selectedAddressData = action.payload;
      })
      .addCase(deleteDeliveryAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDeliveryAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let newArr = state.userAddress?.data?.filter(function (item) {
          return item._id !== action.payload.data._id;
        });
         state.userAddress ={...state.userAddress , data:newArr}
      })
     
  },
});

export const userSelector = (state) => state.user.userdata;
export const loginAdminSelector = (state) => state.user.adminlogin;
export const userAddressSelector = (state) => state.user.userAddress;
export const selectedAddresSelector = (state) => state.user.selectedAddressData;

export default UserSlice.reducer;
