import { Box, Text } from "@chakra-ui/react";
import "./Product.css";
import ProductCards from "./ProductCards";
import { useSelector } from "react-redux";
import { selectAdmincategoryData} from "../../features/Category/CategorySlice";

const style = {
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  productCardsMainBox: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",

    margin: "auto",
    gap: "20px",

    marginLeft: "40px",
  },
};
const CategoryList = () => {
  const category = useSelector(selectAdmincategoryData);
  const heading = "Category List";
   console.log(category,"KKOOKO");

  
  return (
    <>
      <Box>
        <Box marginBottom={"30px"}>
          <Text style={style.formheading}>{heading}</Text>
        </Box>
        <Box style={style.productCardsMainBox}>
          {category.length > 0 &&
            category.map((el) => (
              <ProductCards
                active={el.status}
                id={el._id}
                heading={heading}
                name={el?.categoryName}
                image={el?.categoryImage}
                categoryDescription={el?.categoryDescription}
              />
            ))}
        </Box>
      </Box>
    </>
  );
};

export default CategoryList;
