import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const style = {
  formInput: {
    width: "100%",
    height: "50px",
    borderRadius: "14px",
    border: "1px solid #A0A0A0",
    backgroundColor: "#FFF",
    paddingLeft: "10px",
    marginBottom: "12px",
  },
  formText: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "6px",
  },
};

const ProductSpecification = ({ specifications, onSpecificationChange }) => {
 
  const addSpecification = () => {
    const newSpecifications = [
      ...specifications,
      { name: "", description: "" },
    ];
    onSpecificationChange(newSpecifications);
  };

  const removeSpecification = (name) => {
    const newSpecifications = specifications.filter(
      (spec) => spec.name !== name
    );
    onSpecificationChange(newSpecifications);
  };

  const handleNameChange = (index, newName) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[index].name = newName;
    onSpecificationChange(updatedSpecifications);
  };

  const handleDescriptionChange = (index, newDescription) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[index].description = newDescription;
    onSpecificationChange(updatedSpecifications);
  };

  return (
    <div>
      {specifications?.map((specification, index) => (
        <Box
          key={index}
          style={{
            display: "grid",
            gridTemplateColumns: "300px 400px 50px",
            justifyContent: "space-around",
            borderRadius: "14px",
            padding: "20px",
            marginBottom: "12px",
          }}
        >
          <Box>
            <Text style={style.formText}>Specification Name</Text>
            <Input
              style={style.formInput}
              value={specification.name}
              onChange={(e) => handleNameChange(index, e.target.value)}
            />
          </Box>
          <Box>
            <Text style={style.formText}>Specification Description</Text>
            <Input
              style={style.formInput}
              value={specification.description}
              onChange={(e) => handleDescriptionChange(index, e.target.value)}
            />
          </Box>
          <Box>
            {index === specifications.length - 1 ? (
              <IconButton
                w="50px"
                h="50px"
                borderRadius={"14px"}
                border="1px solid #A0A0A0"
                backgroundColor={"#FFFFFF"}
                mt="30px"
                icon={<AddIcon w="24px" h="24px" color={"#8E8E8E"} />}
                onClick={addSpecification}
              />
            ) : (
              <IconButton
                w="50px"
                h="50px"
                borderRadius={"14px"}
                border="1px solid #A0A0A0"
                backgroundColor={"#FFFFFF"}
                mt="30px"
                icon={<MinusIcon w="24px" h="24px" color={"#8E8E8E"} />}
                onClick={() => removeSpecification(specification.name)}
              />
            )}
          </Box>
        </Box>
      ))}
    </div>
  );
};

export default ProductSpecification;
