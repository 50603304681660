// A mock function to mimic making an async request for data
import axios from "axios";
export function createGovermentschme(Govermentschme) {
  return new Promise(async (resolve) => {
  const response = await axios.post(process.env.REACT_APP_BASE_API+"/govermentschme/addGovermentschme", Govermentschme);
    const data = response.data;

    resolve({ data });
  });
}


export function fetchGovermentschme(page) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
     `${process.env.REACT_APP_BASE_API}/govermentschme/getGovermentschme?page=${page}`
    );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchGovermentschmeByAdmin(page) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/govermentschme/getGovermentschmeAdmin?page=${page}`
    );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchGovermentschmeById(govermentschmeId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/govermentschme/getGovermentschmeById/${govermentschmeId}`
    );

    const data = response.data;
    resolve({ data });
  });
}
export async function fetchGovermentschmeByState({ govermentschmeState, currentPage,type }) {
    return new Promise(async (resolve) => {
      console.log(type,"AKASJ")
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API}/govermentschme/getGovermentschmeByState/${govermentschmeState}?page=${currentPage}&type=${type}`
      );

      const data = response.data;
       resolve({ data });
     
    });
  
}

export function updateGovermentschme({govermentschmeId,currentPage,govermentschmeState,data}) {
    let newSbhme = data;
  return new Promise(async (resolve) => {

   let response =  await axios.put(
    process.env.REACT_APP_BASE_API+"/govermentschme/updateGovermentschme",
      {
        govermentschmeId: govermentschmeId,
        govermentschemeName: newSbhme.govermentschemeName,
        statename: newSbhme.statename,
        content: newSbhme.content,
        image: newSbhme.image,
      }
    );
   const data = response.data;
   resolve({ data });
    
  });
}

export function deleteGovermentschmeById({govermentschmeId}) {
  return new Promise(async (resolve) => {
    let response = await axios.patch(
      `${process.env.REACT_APP_BASE_API}/govermentschme/deleteGovermentschme/${govermentschmeId}`
    );
      
    const data = response.data;
    resolve({ data });
  });
}
export function toggleGovermentschmeStatus({govermentschmeId}) {
  return new Promise(async (resolve) => {
    console.log(govermentschmeId);
  let response=  await axios.patch(
      `${process.env.REACT_APP_BASE_API}/govermentschme/toggleGovermentschmeStatus/${govermentschmeId}`
    );
    
    const data = response.data;
    resolve({ data });
  });
}