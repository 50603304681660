import { Box, Button, Input, Select, Text, Textarea } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import { createGovermentschmeAsync } from "../../features/Govermentschme/GovermentschmeSlice";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";


const style = {
  form_mainbox: {
    width: "600px",
    borderRadius: "28px",
    background: "#f3ffe1",
    padding: "24px",
    margin: "auto",
    marginTop: "20px",
  },
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  formInput: {
    width: "100%",
    height: "50px",
    borderRadius: "14px",
    border: "1px solid #A0A0A0",
    backgroundColor: "#FFF",
    paddingLeft: "10px",
    marginBottom: "12px",
  },
  formText: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "6px",
  },
  formButton: {
    borderRadius: "14px",
    background: "#52771A",
    color: "#f3ffe1",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px 16px",
  },
  btnbox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  errorstyle: {
    color: "red",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "4px",
  },
};

const state = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];

const AddGovermentSchme = () => {
 const dispatch = useDispatch();
 const {
   register,
   handleSubmit,
     setValue,
   formState: { errors },
 } = useForm();

 const onSubmit = (data) => handleFormSubmit(data);

 const handleFormSubmit = async (data) => {
   console.log(data, "sub");
   let imageUrls = null;

   const formData = new FormData();
   formData.append("file", data.image[0]);
   formData.append("upload_preset", "mun8qyj6");
   formData.append("folder", "Govermentscheme");

   try {
     const response = await axios.post(
       "https://api.cloudinary.com/v1_1/dbliylxne/image/upload",
       formData
     );
     imageUrls = response.data.secure_url;
     console.log("Upload to Cloudinary:", response.data.secure_url);
   } catch (error) {
     console.error("Image upload failed:", error);

     Swal.fire({
       icon: "error",
       title: "Oops...",
       text: "Image upload failed.",
     });
     return;
   }

   if (imageUrls) {
     data.image = imageUrls;
     dispatch(createGovermentschmeAsync(data)).then((res)=>{

         if(res?.payload?.status ===1 ){
                toast.success("Schems Added SucessFully");

            setValue("govermentschemeName", "");
            setValue("content", "");
            setValue("statename", "");
            setValue("image", "");
         }
     });
   }

 };

  return (
    <Box>
      <Toaster toastOptions={{ duration: 2000 }} />
      <Box display={"flex"} justifyContent="space-between">
        <Text style={style.formheading}>Add Goverment Schme</Text>
        <Link to="/govermentschmelist">
          <Button style={style.formButton} mr="50px" mt={"45px"}>
            SHOW GOVERMENT SCHME LIST
          </Button>
        </Link>
      </Box>

      <Box style={style.form_mainbox}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text style={style.formText}>Goverment Schme Name</Text>
          <Input
            id="govermentschemeName"
            {...register("govermentschemeName", {
              required: "Goverment-scheme name must be required",
            })}
            style={style.formInput}
          />
          {errors.govermentschemeName && (
            <span style={style.errorstyle}>
              {errors.govermentschemeName.message}
            </span>
          )}
          <Text style={style.formText}>State Name</Text>
          <Select
            id="statename"
            {...register("statename", {
              required: "state name must be required",
            })}
            style={style.formInput}
          >
            {state.map((el) => (
              <option>{el}</option>
            ))}
          </Select>
          {errors.statename && (
            <span style={style.errorstyle}>{errors.statename.message}</span>
          )}
          <Text style={style.formText}>Goverment Schme Description</Text>
          <Textarea
            id="content"
            {...register("content", {
              required: "Goverment-schme description must be required",
            })}
            style={style.formInput}
          />
          {errors.content && (
            <span style={style.errorstyle}>{errors.content.message}</span>
          )}
          <Text style={style.formText}>Goverment Schme Images</Text>
          <Input
            id="image"
            {...register("image", {
              required: "image must be required",
            })}
            type="file"
            style={style.formInput}
          />
          {errors.image && (
            <span style={style.errorstyle}>{errors.image.message}</span>
          )}

          <Box style={style.btnbox}>
            <Button type="submit" style={style.formButton}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default AddGovermentSchme;
