import axios from "axios";

// A mock function to mimic making an async request for data

  
export function addDeliveryAddress(id, address) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/addDeliveryAddress",
      {
        user_id: id,
        address: address,
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function getDeliveryAddress(id) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/getDeliveryAddress",
      {
        user_id: id,
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function updateDeliveryAddress(user_id, address_id, updatedAddress) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/updateDeliveryAddress",
      {
        user_id,
        address_id,
        updatedAddress,
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function updateUser({UserData,userId}) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/updateUser",
      {
      fullName: UserData.fullName,
      email: UserData.email,
      // mobileNo: req.body.userData.mobileNo,
      // city: req.body.userData.city,
      state: UserData.state,
      pincode:UserData.pincode
       ,
        userId:userId
      }
    );
    const data = response.data;
    console.log(data,"AKAKAKAK")
    resolve({ data });
  });
}
export function updateSelectedAddress(user_id, address_id) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/selectedaddress/addselectAddress",
      {
        user_id,
        address_id,
        
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function deleteDeliveryAddress(user_id, address_id) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/deleteDeliveryAddress",
      {
        user_id,
        address_id,
      }
    );
    const data = response.data;

    resolve({ data });
  });
}

export function fetchSelectedAddress(id) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+`/selectedaddress/getselectAddress/${id}`
    );
    const data =  response.data;
       resolve({ data });
  });
}

export function loginAdmin(userData) {
  
  return new Promise(async (resolve) => {
    const response = await axios.post(process.env.REACT_APP_BASE_API+"/users/adminLogin", {
      userData: userData,
    }); 
    if(response?.data?.status === 1){
      console.log(response.data.data.token)
      sessionStorage.setItem('adminToken', response.data.data.token);
           
    }
    const data = response.data;

    resolve({ data });
  });
}
export function authMiddleware() {
  const token = sessionStorage.getItem("adminToken");
  return new Promise(async (resolve) => {
    const response = await axios.post(process.env.REACT_APP_BASE_API+"/users/authMiddleware", {
      token: token,
    }); 
    const data = response.data;

    resolve({ data });
  });
  
}

export function verifyOtpEmail({email,otp}) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/verifyOtpEmail",
      {
        email: email,
        otp : otp
      }
    );
    const data = response.data;

    resolve({ data });
  });
}



