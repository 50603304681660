import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import "./AdminNav.css";
import logo from "../../images/logo.jpg";
import { useDispatch } from "react-redux";
import { fetchCategoryAdminAsync } from "../../features/Category/CategorySlice";
import { useEffect } from "react";
import { fetchServiceAdminAsync } from "../../features/Service/ServiceSlice";
import { fetchSubcategoryAdminAsync } from "../../features/Subcategory/SubcategorySlice";
import { fetchProductAdminAsync } from "../../features/Product/ProductSlice";

const AdminNavbar = () => {
  const dispatch = useDispatch();

  useEffect(() =>{
 dispatch(fetchCategoryAdminAsync());
 dispatch(fetchServiceAdminAsync());
  dispatch(fetchSubcategoryAdminAsync());
   dispatch(fetchProductAdminAsync());

  },[])
  return (
    <Box id="admin_nav">
      <Box id="logo_name_box">
        <Image src={logo} alt="logo" width={"55px"} height="78px"></Image>
        <Text id="name">SONAM</Text>
      </Box>
    </Box>
  );
};

export default AdminNavbar;
