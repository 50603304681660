import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendOtpEmail } from "./ContactUsAPI";

const initialState = {
  value: 0,
  status: "idle",
  contactUs: null,
};

export const sendOtpEmailAsync = createAsyncThunk(
  "contactus/sendOtpEmail",
  async (email) => {
    const response = await sendOtpEmail(email);

    return response.data;
  }
);
export const ContactUsSlice = createSlice({
  name: "contactus",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOtpEmailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendOtpEmailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.contactUs = action.payload;
      });
  },
});



export const selectSendMail = (state) => state.contactus.contactUs; 


export default ContactUsSlice.reducer;
