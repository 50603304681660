import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createGovermentschme,
  deleteGovermentschmeById,
  fetchGovermentschme,
  fetchGovermentschmeByAdmin,
  fetchGovermentschmeById,
  fetchGovermentschmeByState,
  toggleGovermentschmeStatus,
  updateGovermentschme,
} from "./GovermentschmeAPI";

const initialState = {
  value: 0,
  status: "idle",
  govermentschmeData: [],
  admingovermentschmeData: {data:{items:[]}},
};

export const createGovermentschmeAsync = createAsyncThunk(
  "govermentschme/addGovermentschme",
  async (GovermentschmeData) => {
    const response = await createGovermentschme(GovermentschmeData);

    return response.data;
  }
);
export const fetchGovermentschmeAsync = createAsyncThunk(
  "govermentschme/fetchGovermentschme",
  async (page) => {
    const response = await fetchGovermentschme(page);

    return response.data;
  }
);
export const fetchGovermentschmeAdminAsync = createAsyncThunk(
  "govermentschme/fetchGovermentschmeByAdmin",
  async (page) => {
    const response = await fetchGovermentschmeByAdmin(page);
    console.log(response.data, "pall");
    return response.data;
  }
);
export const updateGovermentschmeAsync = createAsyncThunk(
  "govermentschme/updateGovermentschme",
  async ({ govermentschmeId, currentPage, govermentschmeState, data }) => {
    const response = await updateGovermentschme({
      govermentschmeId,
      currentPage,
      govermentschmeState,
      data,
    });

    return response.data;
  }
);
export const fetchGovermentschmeByIdAsync = createAsyncThunk(
  "govermentschme/fetchGovermentschmeById",
  async (govermentschmeId) => {
    const response = await fetchGovermentschmeById(govermentschmeId);

    return response.data;
  }
);
export const fetchGovermentschmeByStateAsync = createAsyncThunk(
  "govermentschme/fetchGovermentschmeByState",
  async ({ govermentschmeState, currentPage ,type}) => {
    const response = await fetchGovermentschmeByState({
      govermentschmeState,
      currentPage,
      type
    });

    console.log(response, "aaaja data");
    return response.data;
  }
);
export const deleteGovermentschmeAsync = createAsyncThunk(
  "govermentschme/deleteGovermentschmeById",
  async ({ govermentschmeId, currentPage, govermentschmeState }) => {
    const response = await deleteGovermentschmeById({
      govermentschmeId,
      currentPage,
      govermentschmeState,
    });

    return response.data;
  }
);
export const toggleGovermentschmeStatusAsync = createAsyncThunk(
  "govermentschme/toggleGovermentschmeStatus",
  async ({ govermentschmeId, currentPage, govermentschmeState }) => {
    const response = await toggleGovermentschmeStatus({
      govermentschmeId,
      currentPage,
      govermentschmeState,
    });

    return response.data;
  }
);

export const GovermentschmeSlice = createSlice({
  name: "govermentschme",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createGovermentschmeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createGovermentschmeAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.admingovermentschmeData.data.items.push(action.payload.data);
      })
      .addCase(fetchGovermentschmeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGovermentschmeAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.govermentschmeData = action.payload;
      })
      .addCase(fetchGovermentschmeAdminAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGovermentschmeAdminAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.admingovermentschmeData = action.payload;
      })
      .addCase(fetchGovermentschmeByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGovermentschmeByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.govermentschmeData = action.payload;
      })
      .addCase(fetchGovermentschmeByStateAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGovermentschmeByStateAsync.fulfilled, (state, action) => {
        state.status = "idle";
         state.admingovermentschmeData = action.payload;
         state.govermentschmeData = action.payload;
      })
      .addCase(updateGovermentschmeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateGovermentschmeAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let newArr = state.admingovermentschmeData.data?.items?.map(function (
          item
        ) {
          if (item._id === action.payload.data.id) {
            return { ...item, ...action.payload.data.updated };
          } else {
            return item;
          }
        });
        const data = {...state.admingovermentschmeData.data , items:newArr}
        state.admingovermentschmeData ={...state.admingovermentschmeData , data:data} ;
      })
      .addCase(deleteGovermentschmeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteGovermentschmeAsync.fulfilled, (state, action) => {
        state.status = "idle";
         let newArr = state.admingovermentschmeData?.data?.items.filter(function (item) {
           return item._id !== action.payload.data._id;
         });
 const data = {...state.admingovermentschmeData.data , items:newArr}
    state.admingovermentschmeData ={...state.admingovermentschmeData , data:data}})

      .addCase(toggleGovermentschmeStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleGovermentschmeStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let newArr = state.admingovermentschmeData?.data?.items.map(function (item) {
          if (item._id === action.payload.data.id) {
            return { ...item, status: action.payload.data.status };
          } else {
            return item;
          }
        });
        const data = {...state.admingovermentschmeData.data , items:newArr}
        state.admingovermentschmeData ={...state.admingovermentschmeData , data:data} ;
      });
  },
});

// export const { increment } = UserSlice.actions;

export const selectGovermentschme = (state) => state.govermentschme.govermentschmeData;
export const selectAdminGovermentschme = (state) =>
  state.govermentschme.admingovermentschmeData;

export default GovermentschmeSlice.reducer;
