import React from "react";
import { Route, Routes } from "react-router-dom";

import AdminOtp from "../Admin/AdminOtp";
import AdminLogin from "../Admin/AdminLogin";

const LoginRouterPage = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminLogin/>}></Route>
      <Route path="/otp-login" element={ <AdminOtp/>}></Route>
    </Routes>
  );
};

export default LoginRouterPage;
