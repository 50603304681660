import React from 'react'
import admin_bg from "../images/admin_background.jpg";
import logo from "../images/logo.jpg";
import { Box, Button, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate  } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginAdminAsync } from '../features/User/UserSlice';
import { toast, Toaster } from "react-hot-toast";


const AdminLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const backgroundStyle = {
      backgroundImage: `url(${admin_bg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh', // Adjust the height as needed
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  
    return (
      <div style={backgroundStyle}>
               <Toaster position="bottom-center"  toastOptions={{ duration: 3000 }} />

          <Box width="400px" backgroundColor="white" display="flex" justifyContent="center" alignItems="center" minHeight="60vh" paddingLeft={"30px"} paddingRight={"30px"} paddingBottom={"30px"}>
          
      <Box width="100%">
        <Box textAlign="center">
          <img src={logo} style={{ maxWidth: "140px", height: "100px" ,margin:"auto", }} alt="Logo"  />
          <Text fontSize={"20px"} marginTop={0}>Welcome Back!!</Text>
        </Box>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required("Email is required").email("Enter valid email address"),
            password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
          })}
          onSubmit={(fields) => {
            dispatch(loginAdminAsync(fields)).then((res) => {
                if (res.payload.status === 1) {
                  toast.success(res.payload.message, { style: { background: "green", color: "#ffffff" } });
                    navigate('/dashboard');
                
                } else {
                  toast.error(res.payload.message, { style: { background: "red", color: "#ffffff" } });
                }
              });
              
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Field name="email" type="email" as={Input} className={errors.email && touched.email ? "is-invalid" : ""} />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Field name="password" type="password" as={Input} className={errors.password && touched.password ? "is-invalid" : ""} />
                <ErrorMessage name="password" component="div" className="invalid-feedback" />
              </FormControl>
              <Button type="submit" color={"#ffffff"} backgroundColor="#52771a" mt={4} w="100%">
  Login
</Button>
              <Link to="/otp-login">
              <Button  color={"#ffffff"} backgroundColor="#52775a"  mt={4} w="100%">
                Login with otp
              </Button>
              </Link>
            </Form>
          )}
        </Formik>

        <hr />

      </Box>
    </Box>
      </div>
    );
  };

export default AdminLogin