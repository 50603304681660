import { Box, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./Product.css";
// import Pagination from "...";

import { useDispatch, useSelector } from "react-redux";

import ServiceCard from "./ServiceCard";
import {
  fetchGovermentschmeAdminAsync,
  fetchGovermentschmeByStateAsync,
  selectAdminGovermentschme,
  
} from "../../features/Govermentschme/GovermentschmeSlice";
import Pagination from "../../component/Pagination";

const state = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];
const style = {
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  productCardsMainBox: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",

    margin: "auto",
    gap: "40px",
  },
};
const GovermentschmeList = () => {
  const govermentschme = useSelector(selectAdminGovermentschme);
  const [govermentschmeState, setGovermentschmeState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const dispatch = useDispatch();


  useEffect(() => {
    if (govermentschmeState) {
      dispatch(
        fetchGovermentschmeByStateAsync({ govermentschmeState, currentPage })
      );
    } else {
      dispatch(fetchGovermentschmeAdminAsync(currentPage));
    }
  }, [currentPage, govermentschmeState]);

  let totalPages = Math.ceil(
    govermentschme?.data?.totalCount / govermentschme?.data?.itemsPerPage
  );

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        marginBottom={"30px"}
      >
        <Text style={style.formheading}>Govermemt Schme List</Text>
        <Select
          placeholder="Select option"
          width="200px"
          height="50px"
          borderRadius="14px"
          border="1px solid #A0A0A0"
          background=" #F9F9F9"
          color="#8E8E8E"
          fontFamily="Inter"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="500"
          mt="30px"
          onChange={(event) => {
            const selectedValue = event.target.value;
            setCurrentPage(1);
            setGovermentschmeState(selectedValue);
          }}
        >
          {state.map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </Select>
      </Box>

      <Box>
        
        {govermentschme.data?.items.length > 1 &&
          govermentschme?.data?.items.map((el) => (
            console.log(el,"NEW"),
            <ServiceCard
              active={el.status}
              id={el._id}
              name={el?.govermentschemeName}
              state={el?.statename}
              content={el.content}
              image={el.image}
              page={currentPage}
              govermentschmeState={govermentschmeState}
            />
          ))}
      </Box>
      <Pagination
        totalPages={totalPages}
        currentPage={govermentschme?.data?.page}
        onPageChange={handlePageChange}
        itemPerPage={govermentschme?.data?.itemsPerPage}
      />
    </Box>
  );
};

export default GovermentschmeList;
